import React from "react"
import { graphql } from "gatsby"
import get from "lodash/get"
import linkBuilder from "../../modules/linkBuilder"

/* Components */
import Layout from "../../components/layout"
import Seo from "../../components/seo/seo"
import SocialMeta from "../../components/social-meta/social-meta"
import StickyNav from "../../components/sticky-nav/sticky-nav"
import Footer from "../../components/footer/footer"
import Outgrow from "../../components/outgrow/outgrow"
import RelatedArticles from "../../components/related-articles/related-articles"
import CtaNewsLetterArt from "../../components/side-column/cta-newsletter/cta-newsletter-art"
import EntryList from "../../components/entry-list/entry-list"
import { Helmet } from "react-helmet"

import {
  tool as toolClass,
  ToolHeader,
  title,
  description as descriptionClass,
  CallToActionWrapper,
  CallToActionWrapperInner,
  ContentAndAsideWrapper,
  ContentBodyAside,
  ContentBodyMain,
  subtitle
} from "./tool.module.css"

import { TocAsideHide } from "../article/article.module.css"

class ToolTemplate extends React.Component {
  render() {
    const outgrow = get(this.props, "data.contentfulOutgrow")
    const outgrowDescription = get(
      outgrow,
      "description.childMarkdownRemark.rawMarkdownBody"
    )
    const relatedArticles = get(outgrow, "relatedArticles")
    const desks = get(this.props, "pageContext.desks")
    const tools = get(this.props, "pageContext.tools")
    const pageUrl = `https://www.mkbservicedesk.nl/${linkBuilder.tool(outgrow)}`
    const publisherLogo = get(
      this.props,
      "data.publisherLogo.childImageSharp.gatsbyImageData.images.fallback.src"
    )

    const ldJson = {
      "@context": "http://schema.org",
      "@type": "NewsArticle",
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": pageUrl,
      },
      headline: outgrow.title,
      inLanguage: "nl",
      datePublished: outgrow.updatedAtInt,
      dateModified: outgrow.updatedAtInt,
      publisher: {
        "@type": "Organization",
        name: "MKB Servicedesk",
        logo: {
          "@type": "ImageObject",
          url: `https://www.mkbservicedesk.nl${publisherLogo}`,
          height: "60",
          width: "537",
        },
      },
      description: outgrowDescription,
    }

    return (
      <Layout location={this.props.location}>
        <Seo title={outgrow.title} description={outgrowDescription} />
        <SocialMeta
          title={outgrow.title}
          description={outgrowDescription}
          image={
            outgrow.image &&
            outgrow.image.gatsbyImageData &&
            outgrow.image.gatsbyImageData.images.fallback.src
          }
          url={linkBuilder.tool(outgrow)}
        />
        <Helmet>
          <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
        </Helmet>
        <StickyNav desks={desks} />
        <main>
          <div className="container">
            <article id="main-tool" className={`${toolClass} clearfix`}>
              <div className={ToolHeader}>
                <header>
                  <h1 className={title}>{outgrow.title}</h1>
                  <p className={subtitle}>{outgrow.subtitle}</p>
                </header>
              </div>
              <div className={ContentAndAsideWrapper}>
                <div className={ContentBodyMain}>
                  {outgrowDescription && (
                    <p className={descriptionClass}>{outgrowDescription}</p>
                  )}

                  <Outgrow id={outgrow.outgrowId} />
                </div>

                {/* START SIDEBAR */}
                <aside className={ContentBodyAside}>
                  <div>
                    <div className={TocAsideHide}>
                      {/* START HIDDEN CONTENT */}
                      {tools && (
                        <EntryList
                          title="Bekijk ook onze andere tools"
                          items={tools}
                          marginTop="0px"
                        />
                      )}
                      {/* END HIDDEN CONTENT */}
                    </div>
                  </div>
                </aside>
                {/* END SIDEBAR */}
              </div>
            </article>
            {relatedArticles && relatedArticles.length > 0 && (
              <RelatedArticles articles={relatedArticles} />
            )}

            <div className={CallToActionWrapper}>
              <div className={CallToActionWrapperInner}>
                <div className="newsletter">
                  <CtaNewsLetterArt
                    title={"Ontvang Handige Tools Direct in Je Inbox!"}
                    description={
                      "Blijf up-to-date met de nieuwste tools en hulpmiddelen. Schrijf je nu in voor onze nieuwsbrief!"
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </Layout>
    )
  }
}

export default ToolTemplate

export const pageQuery = graphql`
  query OutgrowBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }

    publisherLogo: file(relativePath: { eq: "logo-mkbservicedesk.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 537, height: 60, quality: 100)
      }
    }

    contentfulOutgrow(slug: { eq: $slug }) {
      outgrowId
      slug

      updatedAt(formatString: "DD MMMM YYYY", locale: "nl")
      updatedAtInt: updatedAt(formatString: "YYYY-MM-DD")

      title
      subtitle
      description {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      image {
        title
        gatsbyImageData(
          layout: CONSTRAINED
          width: 728
          quality: 100
          breakpoints: [350, 728]
        )
      }

      relatedArticles {
        id
        publishDate
        updateDate
        title
        slug
        slug2
        categories {
          name
          slug
          slug2
          legacyId
          priority
          desk {
            name
            slug
            slug2
            legacyId
          }
        }
        legacyId
        priority
        image {
          title
          gatsbyImageData(layout: CONSTRAINED, width: 400, quality: 100)
        }
      }
    }
  }
`
