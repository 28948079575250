import React, { useEffect } from "react"
import { outgrowWrapper } from "./outgrow.module.css"

const Outgrow = ({ id }) => {
  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://dyv6f9ner1ir9.cloudfront.net/assets/js/nloader.js"
    script.async = true
    document.body.appendChild(script)
    script.addEventListener(
      "load",
      () => {
        window.initIframe(id)
      },
      {
        once: true,
      }
    )
    return () => {
      document.body.removeChild(script)
    }
  }, [id])

  return (
    <div className={outgrowWrapper}>
      <div
        className="op-interactive"
        id={id}
        data-url={`https://mkbservicedesk.outgrow.us/${id}?vHeight=1&seamless=1`}
        data-width="100%"
      ></div>
    </div>
  )
}

export default Outgrow
