import React from "react"
import EntryCard from "../entry-card/entry-card"
import linkBuilder from "../../modules/linkBuilder"
import { entryListTitle } from "./entry-list.module.css"

const EntryList = ({ title, items, marginTop, ...props }) => {
  return (
    <div style={{ marginTop: marginTop || "40px" }} {...props}>
      {title && <h2 className={entryListTitle}>{title}</h2>}

      <div style={{ display: "flex", gap: "10px", flexDirection: "column" }}>
        {items.map((item) => {
          let link
          switch (item.__typename) {
            case "ContentfulDownload":
              link = linkBuilder.download(item)
              break
            case "ContentfulArticle":
              link = linkBuilder.article(item)
              break
            case "ContentfulOutgrow":
              link = linkBuilder.tool(item)
              break
            default:
              link = ""
              break
          }
          return (
            <EntryCard
              variant="dark"
              key={`list-entry-${item.id}`}
              title={item.title}
              link={link}
              image={item.image}
            />
          )
        })}
      </div>
    </div>
  )
}

export default EntryList
